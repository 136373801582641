
  .side-panel {
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    background-color: var(--masala);
    display: flex;
    height: 1024px;
    width: 480px;
    align-items: center;
    padding: 1em;
    width: 80%;
    flex-grow: 0;
  }

  @import url("https://fonts.googleapis.com/css?family=Roboto:400");
  
  .screen a {
    display: contents;
    text-decoration: none;
  }
  
  @media ( max-width: 1200px) {
    .container-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      pointer-events: none;
      flex-shrink: 1;
      flex-grow: 1;
      align-items: center;
    }
  
    .container-main > * {
      flex-shrink: 1;
      pointer-events: auto;
    }
  
  }

  @media ( min-width: 1280px) {
    .container-main {
      display: flex;
      flex-direction: row;
      justify-content: center;
      pointer-events: none;
      flex-shrink: 1;
      flex-grow: 1;
      align-items: center;
    }
  
    .container-main > * {
      flex-shrink: 1;
      pointer-events: auto;
    }
  
  }

  .AttribStrength {
    position: absolute;
    height: 20;
    height: 140;
    display: flex; 
  }

  .Houses {
    pointer-events: none;
  }