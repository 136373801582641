.BirthLagna {
    display: flex;
    padding: 2px 2px;
    margin: 8px;
    align-items: center;
    justify-content: center;
    width: 100%; 
  }
  
  .BirthLagnaBody {
    align-items: center;
    width: 100%;  
    max-width: 600px;  
    box-sizing: border-box;
    background: #2F333D;
  }
  .CustomDatePicker {
    color: white;
    background: rgba(231, 230, 226, 0.5)
  }

  .GoogleMapContainer {
    height: 40vh;
    margin: 10px 50px;
    overflow: hidden;
    filter: drop-shadow(-1px 5px 3px #ccc);
  }

  .TimezoneWrapper {
    font-size: 24px;
    margin-top: 15px;
    background-color: darkslateblue;
    color: darkslategray;
    text-align: center;
  }
