@import url(https://fonts.googleapis.com/css?family=Roboto:400);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


  .side-panel {
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    background-color: var(--masala);
    display: flex;
    height: 1024px;
    width: 480px;
    align-items: center;
    padding: 1em;
    width: 80%;
    flex-grow: 0;
  }
  
  .screen a {
    display: contents;
    text-decoration: none;
  }
  
  @media ( max-width: 1200px) {
    .container-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      pointer-events: none;
      flex-shrink: 1;
      flex-grow: 1;
      align-items: center;
    }
  
    .container-main > * {
      flex-shrink: 1;
      pointer-events: auto;
    }
  
  }

  @media ( min-width: 1280px) {
    .container-main {
      display: flex;
      flex-direction: row;
      justify-content: center;
      pointer-events: none;
      flex-shrink: 1;
      flex-grow: 1;
      align-items: center;
    }
  
    .container-main > * {
      flex-shrink: 1;
      pointer-events: auto;
    }
  
  }

  .AttribStrength {
    position: absolute;
    height: 20;
    height: 140;
    display: flex; 
  }

  .Houses {
    pointer-events: none;
  }
.BirthLagna {
    display: flex;
    padding: 2px 2px;
    margin: 8px;
    align-items: center;
    justify-content: center;
    width: 100%; 
  }
  
  .BirthLagnaBody {
    align-items: center;
    width: 100%;  
    max-width: 600px;  
    box-sizing: border-box;
    background: #2F333D;
  }
  .CustomDatePicker {
    color: white;
    background: rgba(231, 230, 226, 0.5)
  }

  .GoogleMapContainer {
    height: 40vh;
    margin: 10px 50px;
    overflow: hidden;
    filter: drop-shadow(-1px 5px 3px #ccc);
  }

  .TimezoneWrapper {
    font-size: 24px;
    margin-top: 15px;
    background-color: darkslateblue;
    color: darkslategray;
    text-align: center;
  }


.Minter {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    max-width:600px;
  }


.OptionsMenu {
    position: absolute;
    left: 20px;
    top: 60px;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border: 2px solid palevioletred;
    border-radius: 3px;    
  }
.RasiChart{
    position: absolute;
    left: 20px;
    top: 840px;
    width: 160px;
    height: 160px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border: 2px solid palevioletred;
    border-radius: 3px;    
  }
  
.NavamshaChart{
    position: absolute;
    left: 800px;
    top: 840px;
    width: 160px;
    height: 160px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border: 2px solid palevioletred;
    border-radius: 3px;    
  }
  
