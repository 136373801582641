.OptionsMenu {
    position: absolute;
    left: 20px;
    top: 60px;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border: 2px solid palevioletred;
    border-radius: 3px;    
  }